import fun from './fun'
import menu from './menu'
import role from './role'
import user from './user'
import conf_query from './conf_query'
import operationLog from './operationLog'

export default [{
  routerNames: ['authority.user'],
  funs: user
},
{
  routerNames: ['authority.operation_log'],
  funs: operationLog
},
{
  routerNames: ['authority.role'],
  funs: role
},
{
  routerNames: ['authority.menu'],
  funs: menu
},
{
  routerNames: ['authority.fun'],
  funs: fun
},
{
  routerNames: ['conf.conf_query'],
  funs: conf_query
}]
