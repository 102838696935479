<template>
  <sign-page
    title-text="功能管理"
    :request="request"
    :column-list="columnList"
    table-size="small"
    :title-menus="titleMenus"
    :table-actions="tableActions"
    :tableActionsWidth="60"
    @tableAction="tableAction"
    tableActionsFixed
    ref="fun-page"
    :need-data-file="true">
    <fm-modal
      :mask-closable="false"
      :title="chooseData ? chooseData.menuNames + '_' + chooseData.name : ''"
      :value="openDialog"
      width="90%"
      v-if="openDialog"
      theme="mh-withe"
      @cancel="openDialog = false">
      <div class="modal-c">
        <div class="api-item">
          <fm-title title-text="无权限API">
          </fm-title>
          <div class="t-c">
            <fm-table
              :column-list="columnListApi"
              :data-list="notChooseApis"
              @tableAction="tableActionApi"
              :table-actions="tableAction1"
              :show-search="true">
            </fm-table>
          </div>
        </div>
        <div class="api-item">
          <fm-title title-text="有权限API" :title-menus="titleMenusApi" @clickTitleMenu="clickTitleMenu">
          </fm-title>
          <div class="t-c">
            <fm-table
              :column-list="columnListApi"
              :data-list="chooseApis"
              @tableAction="tableActionApi"
              :table-actions="tableAction2"
              :show-search="true">
            </fm-table>
          </div>
        </div>
      </div>
    </fm-modal>
  </sign-page>
</template>

<script>
import {
  updateFun
} from '@/syslib/update_fun'

import {
  funRequest as request,
  systemApisGet
} from '@/api'

export default {
  created () {
    if (this.$authFunsProxy.api) {
      this.loadApis()
    }
  },
  methods: {
    async loadData () {
      let data = await request.get()
      data.forEach(v => v.menuNames = v.menus.map(v2 => v2.name).join(','))
      data.forEach(v => v.apiNames = v.apis.map(v2 => v2.name + ':' + v2.path).join(';'))
      return data
    },
    loadApis () {
      systemApisGet().then((data) => {
        data.forEach((item) => {
          item.fullPath = item.serviceName + item.path
        })
        this.apis = data
      })
    },
    clickTitleMenu () {
      console.log(request)
      request.updateApi(this.chooseData.id, {apiIds: this.chooseApis.map(v => v.id)}).then(() => {
        this.$notice.success({
          title: '系统提示',
          desc: '功能API修改完成'
        })
        this.$refs['fun-page'].loadData()
      })
    },
    tableAction (parm) {
      this.chooseData = parm.data
      this.openDialog = true
      let chooseApis = []
      let notChooseApis = []
      let hisApiIds = []
      this.chooseData.apis.forEach((item) => {
        hisApiIds.push(item.id)
      })
      this.apis.forEach((item) => {
        if (hisApiIds.includes(item.id)) {
          chooseApis.push(item)
        } else {
          notChooseApis.push(item)
        }
      })
      this.chooseApis = chooseApis
      this.notChooseApis = notChooseApis
    },
    tableActionApi (data) {
      let dataItem = data.data
      if (data.action === 'add') {
        this.chooseApis.push(dataItem)
        this.notChooseApis = this.notChooseApis.filter(v => v.id !== dataItem.id)
      } else if (data.action === 'del') {
        this.notChooseApis.push(dataItem)
        this.chooseApis = this.chooseApis.filter(v => v.id !== dataItem.id)
      }
    },
  },
  computed: {
    titleMenus () {
      return [{
        key: 'update_all',
        label: '全部更新',
        dealFun: async (vm) => {
          vm.loading = true
          let menus = await vm.$store.dispatch('loadMenuList')
          menus = menus.map(v => v.data).filter(v => v.url)
          await updateFun(menus)
          this.$notice.success({
            title: '系统提示',
            desc: '功能更新完成'
          })
          vm.loading = false
          vm.loadData()
        }
      }]
    },
    titleMenusApi () {
      return [{
        key: 'saveFunApi',
        label: '保存'
      }]
    },
    tableActions () {
      return [{
        key: 'api',
        label: 'API'
      }]
    },
    columnListApi: {
      get () {
        return [{
          field: 'name',
          title: 'API名称'
        },
        {
          field: 'fullPath',
          title: '路径'
        },
        {
          field: 'method',
          title: '类型'
        }]
      }
    },
    columnList: {
      get () {
        let data = [{
          title: 'key',
          sort: true,
          field: 'funKey'
        },
        {
          title: '名称',
          sort: true,
          field: 'name'
        },
        {
          title: '所属菜单',
          sort: true,
          field: 'menuNames'
        },
        {
          title: '涉及api',
          sort: true,
          field: 'apiNames'
        }]
        return data
      }
    }
  },
  data () {
    return {
      request: Object.assign({}, request, {
        get: this.loadData
      }),
      tableAction1: [{
        label: '添加',
        key: 'add'
      }],
      tableAction2: [{
        label: '删除',
        key: 'del'
      }],
      apis: [],
      chooseData: null,
      openDialog: false,
      chooseApis: [],
      notChooseApis: []
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../styles/values.less';
.modal-c {
  height: 30rem;
  width: 100%;
  display: flex;
  .t-c {
    flex: 1;
  }
  .api-item {
    margin: 0 1rem;
    border: @size-border solid @color-border;
    border-radius: @size-border-radius;
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}
</style>